/**
 * Middleware to redirect to the first document of a product if no document is specified in the route.
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const { $portalApi, $portalApiDev, $i18n } = useNuxtApp()
  const isApiCustomLandingPage: boolean = [
    'products-product_id',
    'products-product_id-versions',
    'products-product_id-versions-version_id',
  ].includes(to.name)

  // If the user attempts to navigate to `/products/{id}` directly, or to a /docs page with no document_slug, redirect to the first document of the product.
  // @ts-ignore - route params exist
  if (isApiCustomLandingPage || (to.path.includes('/docs') && !to.params.document_slug)) {
    try {
      const { enabled: previewModeEnabled, state: previewModeState } = usePortalPreviewMode()
      // Important: must match the fetchKey in `PageMdcContent.vue` to ensure the cache is invalidated when the page is updated.
      const pageFetchKey = computed((): string => `portal-page${previewModeEnabled.value ? `-preview-${previewModeState.value.preview_id}` : ''}${to.path === '/' ? '-home' : to.path.replace(/\//g, '-')}`)
      // Check for any already-cached custom page data
      const { data: cachedPageData } = useNuxtData(pageFetchKey.value)
      // Initialize a boolean to determine if the custom page exists
      let customPageExists: boolean = cachedPageData.value?.content !== undefined

      // If attempting to navigate to the product landing page, first check if the page exists
      if (isApiCustomLandingPage && !customPageExists) {
        try {
          const customPage = await $portalApiDev('/api/v2/portal/pages/content',{
            query: {
              path: to.path, // query the page by path
            },
            ignoreResponseError: true, // Ignore errors since we just want to know if the page exists
            onResponse: async () => {
              await refreshNuxtData(pageFetchKey.value) // Invalidate pageData in the background if the request succeeded.
            },
          })
          // If the custom page exists, set the boolean to true
          if (customPage?.content !== undefined) {
            customPageExists = true
          }
        } catch {
          // no-op; if the custom page doesn't exist, just let the logic below continue
        }
      }

      // If the custom page exists, exit early and allow the page navigation to continue
      if (customPageExists) {
        return
      }

      // @ts-ignore - route params exist
      if (to.path.includes('/docs') && !to.params.document_slug) {
        // If the route is a document route and no document slug is provided, fetch the first document for the product and redirect to it.
        const { data: documents } = await $portalApi('/api/v2/products/{productId}/documents', {
          path: {
            productId: computed(() => {
              // @ts-expect-error - to.params.product_id exists
              return String(to.params.product_id || '')
            }).value,
          },
          headers: { Accept: 'application/vnd.konnect.document-tree+json' },
        })

        if (documents?.length && documents[0]?.slug) {
          // @ts-expect-error - route params exist
          return navigateTo(`/products/${to.params.product_id}/docs/${documents[0].slug}`, {
            redirectCode: 302,
          })
        } else {
          throw createError({
            statusCode: 404,
            statusMessage: $t('errors.documents.no_documents'),
            fatal: true,
          })
        }
      }
    } catch {
      throw createError({
        statusCode: 404,
        statusMessage: $i18n.t('errors.documents.first_document'),
        fatal: true,
      })
    }
  }
})
